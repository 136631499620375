.dashboard-item {
	box-shadow: 0 0 10px rgba($black-color, 0.08);
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	border-radius: 12px;
	height: 100px;
	padding-right: 15px;
	padding-left: 15px;
	width: 95%;
	margin-bottom: 10px;

	&-new {
		border-bottom: 12px solid #f61d25;
		justify-content: center;
		width: 220px;
		img {
			width: 35px;
			padding: 10px;
		}
		span {
			font-size: 14px;
			font-weight: 500;
		}
	}

	&-added {
		height: 120px;
		padding-top: 11px;
		position: relative;
		&-dash {
			justify-content: center;
		}
		img:not(.dashboard-img-item) {
			margin-right: 5px;
			width: 35px;
			border-radius: 100%;
			padding: 6px;
			height: 35px;
		}
		.dashboard-new {
			position: relative;
			width: 100%;
		}
		.button-delete {
			position: absolute;
			top: 40px;
			&-new {
				top: 0px;
				right: 0px;
			}
		}
	}
}

.ant-col-rtl {
	.dashboard-item {
		padding: 13px;
	}
}

.dashboard-card-item {
	&-count {
		font-size: 26px;
		font-weight: 500;
		margin-left: 5px;
	}
	&-time {
		font-size: 12px;
		font-weight: 300;
		color: #4a4a4a;
		text-align: right;
		display: block;
	}
}

.dashboard-switch-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.app-border-item {
	border: 1px solid rgba($color: #707070, $alpha: 0.13);
	border-radius: 10px;
	padding: 20px;

	h2 {
		a {
			font-size: 14px;
			color: #484848;
		}
	}
}

.dashboard-progress-item {
	.ant-progress-circle {
		.ant-progress-text {
			font-size: 15px !important;
			width: 85%;
			margin: 0 auto;
		}
	}
	.stroke-item {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		display: inline-block;
		margin-right: 10px;
	}
	.ant-progress-circle-trail {
		stroke: rgba(156, 58, 255, 0.15);
	}

	.gx-text-muted {
		margin-left: 25px;
	}
}

.dashboard-table-item {
	.ant-table-thead {
		background-color: #f5f5f5;
	}
}

.card-item-details {
	display: flex;
	flex-direction: column;
	width: 150px;
	height: 20px;
}
.main-dashboard {
	width: 100%;
	height: 229px;
	background: #ffffff;
	border-radius: 12px;
}
.user-name {
	padding-inline-start: 5px;
	text-transform: uppercase;
	font-weight: 500;
	color: #00b1ff;
}
.data-dash {
	margin-top: -30px;
}
.data-gery-dashboard {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin-top: 20px;
}
.item-header-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;

	.text {
		width: 100%;
		cursor: pointer;
		text-align: left;
	}
}
.title-margin {
	margin-left: 35px;
	font-size: 14px;
	font-weight: 500;
}

.dropzone:empty {
	overflow-y: hidden;
	min-height: 0px !important;
}

.dropzone {
	display: flex;
	min-height: 300px !important;
	border-radius: 1rem;
	overflow-x: hidden;
	overflow-y: scroll;
}

.drag-wrap {
	position: absolute !important;
}

.grid-item {
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	/* background: #eee; */
	// box-sizing: border-box;
	// text-align: center;
}
