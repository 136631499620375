  @media screen and (max-width: 991px) {
      .mobile-styles {
          height: calc(100vh - 220px) !important;
      }
  }

  @media screen and (max-width: 575px) {
      .mobile-styles {
          height: calc(100vh - 153px) !important;
      }
  }