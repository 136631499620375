.app-tab-item {
	.ant-tabs-nav {
		min-width: 170px;
	}

	.ant-tabs-tab {
		padding: 15px 15px !important;
		&-active {
			background-color: #f8fafb;
			.ant-tabs-tab-btn {
				color: $black-color !important;
				font-weight: 300;
			}
		}
	}
}

.app-tab-list-item {
	img {
		margin-right: 15px;
	}
}

.icon-tab-item {
	.ant-tabs-ink-bar {
		display: none !important;
	}

	.app-tab-list-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: #6b7a99;
		font-size: 14px;

		img {
			width: 60px;
			height: 60px;
			background-color: rgba($color: #747474, $alpha: 0.5);
			padding: 15px;
			border-radius: 10px;
			margin: 0 auto 10px auto;
		}
	}

	.ant-tabs-tab-active {
		background-color: transparent !important;

		img {
			background-color: #747474;
		}
	}

	.ant-tabs-top > .ant-tabs-nav::before {
		display: none !important;
	}
}

.filled-tab-item {
	.ant-tabs-tab {
		border: 1px solid #9e9e9e;
		color: #9e9e9e;
		padding: 10px 15px !important;
		border-radius: 5px;
	}

	.ant-tabs-tab-active {
		background-color: $primary-color;
		color: #fff;
		border-color: $primary-color;

		.ant-tabs-tab-btn {
			color: #fff !important;
		}
	}
	// .app-tab-item .ant-tabs-tab-active{
	//     border-bottom: 2px solid #BF2D30 !important;
	//     z-index: 2;
	// }
	// .ant-tabs-ink-bar {
	//     background-color: transparent !important;
	//   }
	.ant-tabs-top > .ant-tabs-nav::before {
		display: none !important;
	}
}

.tab-no-border-item {
	.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
		display: none !important;
	}

	&.ant-tabs-top > .ant-tabs-nav::before {
		display: none !important;
	}
}

.ant-tabs-nav-list {
	padding: 8px !important;
}

.large-icon-tab-item {
	.ant-tabs-nav-list {
		width: 400px;
	}

	.ant-tabs-tab {
		border-bottom: 1px solid rgba($color: #747474, $alpha: 0.5);
	}

	&.ant-tabs-left > .ant-tabs-content-holder {
		border-left: 0 !important;
	}

	&.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
		margin-top: 0;
	}

	.app-tab-list-item {
		img {
			background-color: #747474 !important;
			width: 60px;
			height: 60px;
			padding: 10px;
			border-radius: 100%;
		}

		span {
			color: #6b7a99;
			font-size: 16px;
		}
	}

	&.app-tab-item .ant-tabs-tab {
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}
}

.data-tab {
	background-color: #fff;
	border-radius: 12px !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
	background-color: #f5f5f5;
	border: 1px solid #e0e0e0;
	border-right: 0;
	border-radius: 5px 0 0 5px;
	color: #616161;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
	background-color: #f5f5f5;
	border: 1px solid #e0e0e0;
	border-right: 0;
	border-radius: 5px 0 0 5px;
	color: #616161;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
	background-color: #f5f5f5;
	border: 1px solid #e0e0e0;
	border-right: 0;
	border-radius: 5px 0 0 5px;
	color: #616161;
}

.nested-tab-container-item {
	border: 1px solid rgba($color: #747474, $alpha: 0.5);
	padding: 20px;
	margin-top: 30px;

	p {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			font-size: 20px;
		}
	}

	.ant-form {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.tabs-general {
	.ant-tabs-tab {
		flex: none !important;
		justify-content: flex-start;
	}
	.app-tab-item .ant-tabs-tab-active {
		background-color: #f7fafd !important;
		color: #fff;
		border-color: $primary-color;

		.ant-tabs-tab-btn {
			color: #3361ff !important;
		}
	}
}
