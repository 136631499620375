.ant-switch {
    min-width: 35px;
}

.theme-switch-item {
    font-size: 16px;
    margin-right: 10px;
}

.gx-language-name {
    text-transform: uppercase;
}