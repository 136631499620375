
.color-picker-item {
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;
    margin-top: -10px;
	margin: 0px;
	padding-left: 0px;

    span > div {
        // height: 50px !important;
        // width: 22% !important;
        width: 56px !important;
height: 56px !important;
border-radius: 50% !important;
    }
}

.color-picker-item div:last-child {
	padding-left: 0px !important;
}

.color-picker-modal {

    .ant-modal-footer {
        border: 0;
        padding-bottom: 30px;

        button {
            width: 100%;
            height: 60px;
        }
    }

    p {
        font-size: 18px;
    }
}

.color-picker-input {
    border: 0;
    background-color: #FAFBFC;
    height: 60px;
}
