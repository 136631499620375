.input-day {
  width: 50px !important;
  margin: 0 10px;
  display: inline-block !important;
  border: none !important;
  border-bottom: 1px solid #778CA2 !important;
  border-radius: 0 !important;
  padding: 0 ;
}
.ant-input:focus {
  border-color: #3887db !important;
  box-shadow: none !important;
}

.app-input-item.ant-input {
	border-radius: 6px;
}

