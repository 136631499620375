.button-card{
    background: #EBF4FA !important;
border-radius: 6px !important;
color: #0371BA;
padding-inline-start: 5px;									
}
.button-card-add{
    background: #0371BA !important;
    border-radius: 6px !important;
    color: white;
  	
}
.column-style{
    background: #FFFFFF;
border: 1px solid #0371BA;
border-radius: 6px;
color: #61A6D4;
}
.img-card{
    margin-top: -5px;
    padding-inline-end: 5px;
}
.app-card-item {
   // border: 3px solid #F4F8FE;
    border-radius: 20px;
.ant-card-bordered{
border: none;
}
    .ant-card-head {
    //    background-color: #F4F8FE;
        // background-color: #EFEFEF;
        color: #6B7A99;
        font-size: 16px;
        border-bottom: none;
    }
}
.cards{
	width: 600px !important;
}

.my-checkbox-group{
    background-color: red;
    display: flex !important;
    flex-direction: row !important;
    // flex-wrap: wrap !important;
    
    width: 3%;
    margin-top: 10px;
    margin-bottom: 10px;
  
}
.ant-card-extra {
    padding-top: 5px;
    padding-bottom: 5px;

    .ant-btn {
        margin-bottom: 0;
    }
}

.inside-card-component-item {

    &.app-card-item .ant-card-head {
        padding-left: 20px !important;
    }
}

.sidebar-bordered-item {
    border: 1px solid rgba($color: #4A4A4A, $alpha: 0.3);
    padding: 20px;
    border-radius: 5px;

    .ant-collapse-header {
        background: transparent !important;
    }
}

