.ant-table-thead > tr > th {
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;

    &.ant-table-selection-column {
        position: relative;
        // visibility: hidden;
    }
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.table-header-buttons {
    margin-bottom: 20px;

    .ant-btn {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
        color: #778CA2;
    }
}

.table-booking-item {
    border: 0;

    .ant-card-body {
        padding: 0;
    }

    .table-header-buttons {
        background-color: rgb(250, 251, 252);
        color: #6B7A99;
        font-size: 16px;
        border-bottom: none;
        padding: 15px;
    }
}

.table-search-button-controls {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media screen and (max-width: 910px) {
		flex-direction: column;
	}
}

.table-search-button-controls .ant-input-group-wrapper {
	width: unset !important;
	padding-inline-end: 10px;
	flex-grow: 1;
}
