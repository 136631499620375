.app-date-range-item {
    border: 1px solid rgba($color: $black-color, $alpha: 0.04);
    box-shadow: 0 0 7px rgba($color: $black-color, $alpha: 0.04);
    padding: 20px;

    .rdrCalendarWrapper {
        width: 100%;
    }

    .rdrMonth {
        width: 100%;
    }
    .rdrDateDisplayWrapper {
        display: none;
    }
}