.image {
	border-radius: 50%;
}
.ant-image {
	display:block;
	margin: auto;
}
.ant-modal-content {
	margin: 5% auto auto;
}
