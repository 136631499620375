@import "./variables";

@import "./titles";
@import "./sidebar";
@import "./header";
@import "./dashboard-item";
@import "./tabs";
@import "./cards";
@import "./forms";
@import "./maps";
@import "./tables";
@import "./searchbox";
@import "./modals";
@import "./buttons";
@import "./calendar";
@import "./color-picker";
@import "./date-range";

html.rtl {
	img {
		margin-left: 5px;
	}

	.theme-switch-item {
		margin-left: 10px;
	}

	span.ant-avatar {
		img {
			margin-right: 0;
			margin-left: 0;
		}
	}
}

body {
	background-color: $white-color;
}

.dark-theme .ant-card-head-title {
	color: $black-color;
}

.logout-item {
	position: absolute;
	bottom: 50px;
	margin: 0 20px;

	img {
		margin-right: 10px;
	}

	a {
		color: $text-color;
		font-size: 17px;
		margin-bottom: 10px;
		border-radius: 50px;
		margin-left: 5px;
		position: relative;
		overflow: inherit;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

span.red-text {
	color: red;
}
.rbc-header {
	background-color: rgba(0, 0, 0, 0.06);
}
.rbc-header.rbc-today {
	background-color: rgba(0, 0, 0, 0.06) !important;
}

.rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
	background-color: transparent !important;
}
.setting-device .ant-col-24 {
	padding-left: 0px;
}
.options {
	list-style: none;
	padding-left: 0;
}
.options li {
	display: flex;
	align-items: center;
	padding: 0em 0.8em 0em 0.5em;
	margin-bottom: 0.5em;
}
li .checkbox-reverse .ant-checkbox + span {
	width: 320px;
	@media screen and (max-width: 400px) {
		width: 280px;
	}
}
li .checkbox-reverse .ant-checkbox-checked ~ span {
	background-color: #fff;
	color: #252631 !important;
}

.ant-radio-group {
	@media screen and (max-width: 400px) {
		display: flex;
		flex-direction: column;
		.ant-radio-wrapper {
			margin-bottom: 5px;
		}
	}
}

.icon-padding {
	padding-inline-end: 5px;
}
.border-left {
	border-left: 1px solid #ccc !important;
	border-right: none;
}

.add-visitor {
	padding: 10px 20px;
	margin: 20px auto;

	@media screen and (min-width: 768px) {
		width: 50%;
	}
}
.welcome-name {
	margin-top: 8px;
	font-weight: 500;
	margin-left: -25px;
	margin-right: -35px;
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.dashboard-button {
	@media screen and (max-width: 768px) {
		display: none;
	}
}

.gx-popover-horizantal .ant-popover-inner-content {
	@media screen and (max-width: 575px) {
		width: 120px;
	}
}
.ant-popover-inner {
	@media screen and (max-width: 575px) {
		margin-inline-start: 200px;
	}
}
.fc .fc-toolbar.fc-header-toolbar {
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
}
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
// 	background-color: #efefef;
// }

.cld_noslotWidth {
	@media screen and (max-width: 768px) {
		width: 18em !important;
	}
}
.rbc-header {
	@media screen and (max-width: 768px) {
		writing-mode: vertical-rl;
		text-orientation: mixed;
		font-size: 10px;
	}
}
.rbc-allday-cell {
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.rbc-label.rbc-time-header-gutter,
.rbc-time-gutter.rbc-time-column {
	@media screen and (max-width: 768px) {
		width: 60px !important;
		min-width: 60px !important;
		max-width: 60px !important;
		font-size: 10px;
	}
}

.ql-insert-variable {
	width: 120px;
}
.ql-insert-variable .ql-picker-options {
	height: 150px;
	overflow-y: scroll;
}

.ant-menu-item-active {
	color: #3361ff !important;
	background-color: #fff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item {
	border-radius: 0px;
	margin-left: 0px;
	margin: 0 20px 10px;
	border-radius: 5px;
}
.wrapper-card-model {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.button-copy {
	margin-bottom: 0px;
	background: blue;
	border: none !important;
	width: 100%;
	border-radius: 0 5px 5px 0;
	color: white;
}
.button-copy:hover {
	margin-bottom: 0px;
	background: blue;
	border: none !important;
	width: 100%;
	border-radius: 0 5px 5px 0;
	color: white;
}
.ant-input-group-addon {
	padding: 0px !important;
	border: none !important;
}
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	border-radius: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d8d8d8;
	border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.ant-layout-sider .ant-menu-inline {
	padding: 0 10px !important;
}

.ant-input-number-group-addon:first-child,
.ant-input-number-group-addon:last-child {
	border-right: 0;
	background: #ebf4f9;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-left: 0px !important;
}
.method.ant-checkbox-group {
	flex-direction: row !important;
	width: 100% !important;
}
.method .ant-checkbox-wrapper {
	width: 37% !important;
}
.width-100 {
	width: 100% !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
	padding-left: 35px !important;
}
