.add-users{
width: 780px !important;
transform-origin: 948px 26px;
}
.filter-modal{
	top: 15px;
}
.ant-modal-header {
    background-color: #FAFBFC;
    border-bottom: 0;
    border-radius: 10px;

    .ant-modal-title {
        color: #6B7A99;
        font-weight: 400;
    }
}

.ant-modal-content {
    border-radius: 10px;
    margin: 20% auto auto auto;

}

.ant-checkbox-group-item{
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	gap: 10px;
	width:95%;
	height: 30px;
	grid-gap:0px
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-right: 0;
}
.forget-password .ant-checkbox + span{
	width: 120px;
}
.ant-checkbox + span{
	color:#778CA2;
	width: 380px;
	padding: 0;
	@media screen and (max-width:500px) {
		width: 300px;
	}
}
.check {
	margin-inline-start: 10px;
}
.full-width-checkbox .ant-checkbox-wrapper-checked{
    background-color: #F2F4F6;
   span{
    color: #252631;
   }
}

.full-width-checkbox {
	float: none 	!important;
	margin: 0 		!important;
	width: 380px     !important;
	@media screen and (max-width:500px) {
		width:300px;
	}
}
.checkbox-reverse.ant-checkbox-wrapper {
	flex-direction: row-reverse;
}
.checkbox-reverse .ant-checkbox + span {
  width: 280px
}

.ant-checkbox-group {
	display: flex;
	flex-direction: column;

}

.ant-form-item-label > label {
	font-weight: 600;
	font-size: 14px;
}

.ant-modal-footer {
	text-align: left;
}
.ant-modal-footer .ant-btn {
	margin:0
}
.rlt .ant-btn:not(:last-child) {
	margin-right: 8px !important;
}


