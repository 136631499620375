 .rbc-today{
	background-color: transparent !important;
}


.rbc-time-content {
    border-top: 0;
}

.rbc-header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #252631;
    background-color: rgba($color: (#000000), $alpha: 0.06);

    & + & {
        border-left: 0;
    }
}

.rbc-time-view {
    border: 0;
}

.rbc-day-slot .rbc-time-slot {
    border-color:rgba($color: (#CCCCCC), $alpha: 0.4)
}

.gx-rbc-shifts-calendar {
    .rbc-toolbar {
        margin-left: 20px;

        img {
            margin-right: 5px;
        }

        .ant-btn {
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-right: 10px;

            &:hover, &:active, &:focus {
                background: none !important;
                color: $primary-color;
            }
        }

        button {
            border: none;
            font-size: 18px;
        }

        .rbc-btn-group {
            &:nth-child(3) {
                display: none;
            }
        }
    }
}

.none-buttons-calendar {

    .rbc-btn-group {
        display: none;
    }

}
