
// ColorPicker Variables
$white-color: #ffffff;
$black-color: #000000;
$light-blue-color: #BCE0FD;
$light-blue-2-color: #F1F9FF;

//global style variables
$primary-color: #3361FF;
// $secondary-color: #fa8c15;
$secondary-color:red;
$text-color: #545454;
$text-color-secondary: #595959;
$heading-color: #535353;
$header-text-color: #262626;
$layout-dashboard-background: #E8E8E8;
$layout-dashboard-background-dark: #003365;
$layout-footer-background: #fffffe;
$body-background: #f5f5f5;
$nav-bar-bg: #fffffc;
$nav-bar-text-color: $text-color;

$nav-dark-bg: #003366;
$nav-dark-text-color: #038fdd;
$menu-dark-bg: $nav-dark-bg;
$menu-dark-color: $nav-dark-text-color;
$menu-dark-highlight-color: $secondary-color;
$menu-dark-arrow-color: $nav-dark-text-color;
$hor-nav-text-color: #fffffd;

$border-radius-base: 6px;
$border-radius-sm: 4px;
$border-radius-lg: 10px;
