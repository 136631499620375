.header-blue-item {
    h3 {
        color: $primary-color;

        span {
            color: #9E9E9E;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

span.primary-color {
    color: $primary-color;
    margin-left: 10px;
}

.border-bottom-item {
    border-bottom: 1px solid #707070;
}