.app-searchbox-item {
    width: 307px;
    .ant-input {
        border-radius: 50px;
        background: #F1F5FE !important;
        border: none;
    }

    .gx-search-icon {
        right: 30px;
        top: 1px;

        i {
            color: #C3CAD9;
            font-size: 17px;
        }
    }
}
.ant-input-search {
	.ant-input-group {
		display: flex;
		flex-direction: row-reverse;
	}

	@media screen and (max-width: 910px) {
		// width: 100% !important;
	}
 }
 .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
	background: none !important;
	border: none;
 }
 .ant-input-search  .ant-btn .anticon {
	transform: rotate(90deg);
 }
 .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
padding-inline-start: 30px;
	border-radius: 6px;
 }


