.ant-menu {
	background-color: transparent !important;
	&:not(.ant-menu-horizontal) {
		.ant-menu-item {
			padding-left: 10px !important;
			font-size: 17px;
			margin-bottom: 10px;
			border-radius: 50px 0 0 50px;
			margin-left: 5px;
			position: relative;
			overflow: inherit;

			img {
				margin-right: 12px;
			}

			&-selected {
				background-color: $white-color;

				a {
					color: $black-color;
				}
			}
		}
	}

	&-item:active {
		background-color: $white-color;
		border-radius: 50px 0 0 50px;
	}
}

.ant-menu-inline {
	width: 100%;
	padding-top: 30px;

	> .ant-menu-item {
		height: 52px;
	}
}
.ant-menu-submenu-arrow::before {
	right: -200px;
}
.ant-menu-submenu-arrow::after {
	right: -200px;
}
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
	padding-left: 40px !important;
}
.WorkforceIcon {
	margin-left: -108px !important;
	margin-right: -52px !important ;
	z-index: 111;
	position: absolute;
}
.WorkforceIcon2 {
	//margin-left: -108px !important;
	margin-right: -52px !important ;
	z-index: 111;
	position: absolute;
}
