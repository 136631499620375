
.btn-large-add {
    width: 30px;
    height: 50px;
    margin-bottom: 0;
    margin-right: 20px;

    img {
        width: 100%;
    }
}

.grey-btn {
    background-color: rgba($color: #747474, $alpha: 0.5);
    border: 0;

    &:hover, &:focus, &:active {
        background-color: rgba($color: #747474, $alpha: 0.2) !important;
        opacity: 1;
    }
}
